<template>
  <svg
    width="29"
    height="24"
    viewBox="0 0 29 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 2H27.5"
      stroke="#FAFAFA"
      stroke-width="2.5082"
      stroke-linecap="round"
    />
    <path
      d="M2 12.0328H27.5"
      stroke="#FAFAFA"
      stroke-width="2.5082"
      stroke-linecap="round"
    />
    <path
      d="M2 22.0657H27.5"
      stroke="#FAFAFA"
      stroke-width="2.5082"
      stroke-linecap="round"
    />
  </svg>
</template>
<script>
export default {
  name: 'MenuIcon',
};
</script>

<style scoped lang="scss"></style>
