import { createStore } from 'vuex';

export default createStore({
  state: {
    loading: false,
  },

  getters: {},
  mutations: {
    showLoader(state) {
      state.loading = true;
    },
    hideLoader(state) {
      state.loading = false;
    },
  },
  actions: {},
  modules: {},
});
