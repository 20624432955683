<template>
  <div class="footer_page">
    <div><LogoToFooter class="logo_f" /></div>
    <div>
      <div class="title__footer_page">
        Your night out, with dynamic, simple, value.
      </div>
    </div>
    <div class="box_btn_download">
      <div class="text_download">Download the app on</div>
      <div class="btn__download">
        <a
          href="https://play.google.com/store/apps/details?id=org.lnt.app"
          target="_blank"
          class="btn_google_pay"
        >
          <BtnGooglePay />
        </a>
        <a
          href="https://apps.apple.com/us/app/the-london-nightlife-card/id6502187066"
          target="_blank"
          class="btn_app_store"
          ><BtnAppStore
        /></a>
      </div>
    </div>
  </div>
</template>
<script>
import BtnGooglePay from '@/components/svg/BtnGooglePay.vue';
import LogoToFooter from '@/components/svg/LogoToFooter.vue';
import BtnAppStore from '@/components/svg/BtnAppStore.vue';

export default {
  name: 'FooterPage',
  components: { BtnAppStore, LogoToFooter, BtnGooglePay },
};
</script>

<style scoped lang="scss">
.footer_page {
  display: flex;
  flex-direction: column;
  gap: 36.8px;
  align-items: center;
  background: url('@/assets/images/fon1.png') no-repeat center center;
  padding-top: 135px;
  padding-bottom: 157px;
  position: relative;
  background-size: cover;
}
.footer_page::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #00000080;
  z-index: 1;
}

.footer_page > * {
  position: relative;
  z-index: 2;
}
.title__footer_page {
  font-size: 64px;
  font-weight: 700;
  line-height: 77.45px;
  color: #fafafa;
  max-width: 790px;
  text-align: center;
}
.text_download {
  font-size: 17px;
  line-height: 20.57px;
  font-weight: 500;
  color: #fafafa;
}
.btn__download {
  gap: 12.4px;
  display: flex;
}
.btn_app_store,
.btn_google_pay {
  cursor: pointer;
}
.box_btn_download {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;
  justify-content: center;
}
@media (max-width: 992px) {
  .footer_page {
    padding-top: 108px;
    padding-bottom: 108px;
  }
}
@media (max-width: 768px) {
  .footer_page {
    gap: 24px;
  }
  .title__footer_page {
    font-size: 36px;
    font-weight: 700;
    line-height: 43.57px;
    text-align: center;
  }
  .logo_f {
    width: 82px;
  }
  .title__footer_page {
    max-width: 317px;
  }
}
</style>
