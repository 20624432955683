import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';

// import { createVfm } from 'vue-final-modal';
// import 'vue-final-modal/style.css';

// const vfm = createVfm();
import { setTitle } from './utils/title';

router.beforeEach((to, from, next) => {
  setTitle(to.meta.title);
  next();
});
createApp(App).use(store).use(router).mount('#app');
