<template>
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.00098 2L12.001 13L22.001 2"
      stroke="#FAFAFA"
      stroke-width="2.5082"
      stroke-linecap="round"
    />
    <path
      d="M2.00098 23L12.001 13L22.001 23"
      stroke="#FAFAFA"
      stroke-width="2.5082"
      stroke-linecap="round"
    />
  </svg>
</template>
<script>
export default {
  name: 'CloseSvg',
};
</script>
<style scoped lang="scss"></style>
