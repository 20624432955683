import HomeView from '../views/HomeView.vue';
import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: 'The Nightlife Card – Download for Free Today',
    },
  },
  {
    path: '/contact-us',
    name: 'contactUs',
    meta: {
      title: 'Contact Us – The Nightlife Card',
      requiresAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "contactUs" */ '../views/ContactUsView.vue'),
  },
  {
    path: '/partner',
    name: 'partner',
    meta: {
      title: 'Become a Partner – The Nightlife Card',
      requiresAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "partner" */ '../views/PartnerView.vue'),
  },
  {
    path: '/london/best-bars',
    name: 'bestBars',
    meta: {
      title: 'Best Bars in London – The Nightlife Card',
      requiresAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "bestBars" */ '../views/BestBarsView.vue'),
  },
  {
    path: '/venue/:id',
    name: 'showVenue',
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "showVenue" */ '../views/VenueView.vue'),
  },
  {
    path: '/privacy-policy',
    name: 'privacyPolicy',
    meta: {
      title: 'Privacy Policy - The Nightlife Card',
    },
    component: () =>
      import(
        /* webpackChunkName: "privacyPolicy" */ '../views/PrivacyPolicy.vue'
      ),
  },
  {
    path: '/terms-and-conditions',
    name: 'termsAndConditions',
    meta: {
      title: 'Terms & Conditions - The Nightlife Card',
    },
    component: () =>
      import(
        /* webpackChunkName: "termsAndConditions" */ '../views/TermsAndConditions.vue'
      ),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  linkActiveClass: 'active-link',
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

export default router;
