<script>
export default {
  name: 'BtnAppStore',
};
</script>

<template>
  <svg
    width="164"
    height="54"
    viewBox="0 0 164 54"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M150.53 0.71599H13.6072C13.1081 0.71599 12.615 0.71599 12.1172 0.718608C11.7005 0.721226 11.2871 0.72883 10.8664 0.73523C9.95251 0.745571 9.04072 0.822887 8.13901 0.966504C7.23856 1.11325 6.36632 1.3899 5.55179 1.78711C4.73825 2.18768 3.9949 2.70817 3.3487 3.32972C2.69911 3.94951 2.15763 4.66591 1.74507 5.45137C1.33138 6.23526 1.04452 7.07528 0.894406 7.9424C0.742866 8.80842 0.661321 9.68444 0.650503 10.5626C0.637872 10.9638 0.636538 11.3665 0.629883 11.7678V42.0193C0.636538 42.4257 0.637872 42.8193 0.650503 43.2258C0.661325 44.1039 0.742869 44.9799 0.894406 45.8459C1.0441 46.7135 1.33098 47.554 1.74507 48.3382C2.15744 49.1211 2.699 49.8346 3.3487 50.451C3.99245 51.0753 4.73628 51.5961 5.55179 51.9936C6.36632 52.3919 7.23846 52.6702 8.13901 52.8192C9.04088 52.9617 9.95257 53.039 10.8664 53.0506C11.2871 53.0595 11.7005 53.0646 12.1172 53.0646C12.615 53.0672 13.1081 53.0672 13.6072 53.0672H150.53C151.02 53.0672 151.517 53.0672 152.006 53.0646C152.42 53.0646 152.846 53.0595 153.261 53.0506C154.173 53.0396 155.083 52.9623 155.983 52.8192C156.886 52.6691 157.761 52.3909 158.58 51.9936C159.395 51.5959 160.138 51.0751 160.781 50.451C161.429 49.8322 161.972 49.1192 162.389 48.3382C162.8 47.5534 163.084 46.713 163.232 45.8459C163.384 44.9798 163.468 44.104 163.484 43.2258C163.49 42.8193 163.49 42.4257 163.49 42.0193C163.5 41.5438 163.5 41.0709 163.5 40.5877V13.1967C163.5 12.7174 163.5 12.242 163.49 11.7678C163.49 11.3665 163.49 10.9638 163.484 10.5625C163.468 9.68431 163.384 8.80849 163.232 7.94234C163.084 7.07572 162.8 6.23578 162.389 5.45132C161.549 3.87695 160.217 2.59543 158.58 1.78699C157.761 1.39076 156.886 1.11417 155.983 0.966386C155.083 0.822136 154.173 0.744794 153.261 0.735046C152.846 0.728659 152.42 0.72099 152.006 0.718438C151.517 0.71582 151.02 0.71582 150.53 0.71582V0.71599Z"
      fill="#6C6C6C"
    />
    <path
      d="M12.1241 51.9217C11.7094 51.9217 11.3047 51.9166 10.8933 51.9077C10.041 51.897 9.19064 51.8257 8.34928 51.6942C7.56474 51.5643 6.80475 51.3226 6.09435 50.9771C5.39046 50.6345 4.74846 50.1853 4.19294 49.6466C3.62938 49.1143 3.16019 48.4969 2.80396 47.8189C2.44381 47.1365 2.19456 46.4051 2.0649 45.65C1.92488 44.8387 1.84913 44.0184 1.83829 43.1961C1.82966 42.92 1.81836 42.001 1.81836 42.001V11.7674C1.81836 11.7674 1.83039 10.8625 1.83835 10.5967C1.84873 9.77567 1.92405 8.95659 2.06365 8.14656C2.19355 7.38946 2.44299 6.65588 2.80333 5.97123C3.15825 5.2937 3.62484 4.67584 4.185 4.14163C4.74454 3.60233 5.3886 3.15078 6.09368 2.80344C6.80245 2.45908 7.56101 2.2191 8.34395 2.09154C9.18808 1.95878 10.0414 1.88701 10.8966 1.87682L12.1248 1.86084H151.999L153.242 1.87746C154.09 1.88715 154.935 1.95828 155.772 2.09026C156.562 2.21941 157.329 2.46107 158.046 2.80727C159.458 3.50727 160.608 4.61473 161.333 5.97443C161.687 6.65435 161.933 7.38194 162.061 8.1325C162.202 8.94918 162.281 9.77469 162.298 10.6024C162.302 10.9731 162.302 11.3712 162.302 11.7674C162.312 12.2582 162.312 12.7254 162.312 13.1964V40.5874C162.312 41.0629 162.312 41.5268 162.302 41.9946C162.302 42.4202 162.302 42.8101 162.296 43.2114C162.28 44.0244 162.203 44.8351 162.064 45.6372C161.937 46.3977 161.689 47.1348 161.329 47.8229C160.97 48.4935 160.504 49.1055 159.946 49.6364C159.39 50.178 158.747 50.6299 158.042 50.9747C157.327 51.3228 156.562 51.5654 155.772 51.6942C154.93 51.8264 154.08 51.8977 153.227 51.9077C152.829 51.9166 152.411 51.9217 152.006 51.9217L150.531 51.9243L12.1241 51.9217Z"
      fill="#131313"
    />
    <path
      d="M34.3422 27.285C34.3569 26.1926 34.6586 25.1215 35.2194 24.1714C35.7802 23.2213 36.5818 22.423 37.5497 21.8508C36.9348 21.0064 36.1237 20.3116 35.1807 19.8214C34.2377 19.3311 33.1889 19.0591 32.1174 19.0269C29.8319 18.7962 27.6161 20.342 26.4515 20.342C25.2643 20.342 23.4711 19.0498 21.5401 19.088C20.2911 19.1268 19.0738 19.4761 18.0069 20.1017C16.94 20.7274 16.0598 21.6082 15.4521 22.6582C12.8198 27.0405 14.7833 33.4811 17.3048 37.0235C18.5664 38.7581 20.0408 40.6956 21.97 40.6269C23.8579 40.5516 24.563 39.4693 26.8418 39.4693C29.0995 39.4693 29.761 40.6269 31.7294 40.5832C33.7552 40.5516 35.0315 38.8409 36.2488 37.0899C37.1553 35.854 37.8528 34.488 38.3155 33.0425C37.1386 32.5638 36.1342 31.7626 35.4277 30.7388C34.7211 29.7149 34.3436 28.5137 34.3422 27.285Z"
      fill="#FAFAFA"
    />
    <path
      d="M30.6239 16.6974C31.7284 15.4223 32.2726 13.7835 32.1408 12.1289C30.4533 12.2993 28.8945 13.0749 27.7751 14.301C27.2277 14.9 26.8085 15.5968 26.5414 16.3517C26.2743 17.1065 26.1645 17.9046 26.2184 18.7003C27.0624 18.7087 27.8975 18.5327 28.6606 18.1858C29.4237 17.8389 30.0949 17.3299 30.6239 16.6974Z"
      fill="#FAFAFA"
    />
    <path
      d="M58.2067 36.2356H51.7642L50.2171 40.6284H47.4883L53.5905 24.376H56.4256L62.5278 40.6284H59.7525L58.2067 36.2356ZM52.4314 34.2085H57.5381L55.0207 27.0792H54.9503L52.4314 34.2085Z"
      fill="#FAFAFA"
    />
    <path
      d="M75.7063 34.7043C75.7063 38.3865 73.6567 40.7523 70.5637 40.7523C69.7802 40.7917 69.001 40.6182 68.3153 40.2515C67.6296 39.8849 67.0652 39.34 66.6866 38.6792H66.628V44.5483H64.0986V28.779H66.547V30.7498H66.5935C66.9895 30.0922 67.5635 29.5501 68.2544 29.1813C68.9454 28.8124 69.7274 28.6305 70.5172 28.655C73.6447 28.655 75.7063 31.0323 75.7063 34.7043ZM73.1064 34.7043C73.1064 32.3053 71.8171 30.7281 69.85 30.7281C67.9174 30.7281 66.6174 32.3385 66.6174 34.7043C66.6174 37.0918 67.9174 38.6907 69.85 38.6907C71.8171 38.6907 73.1064 37.125 73.1064 34.7043Z"
      fill="#FAFAFA"
    />
    <path
      d="M89.2697 34.7043C89.2697 38.3865 87.2202 40.7523 84.1272 40.7523C83.3437 40.7917 82.5645 40.6181 81.8788 40.2515C81.1931 39.8849 80.6286 39.34 80.25 38.6792H80.1915V44.5482H77.6621V28.779H80.1104V30.7498H80.1569C80.553 30.0922 81.127 29.5501 81.8179 29.1813C82.5088 28.8124 83.2908 28.6305 84.0806 28.655C87.2082 28.655 89.2697 31.0323 89.2697 34.7043ZM86.6699 34.7043C86.6699 32.3053 85.3806 30.7281 83.4134 30.7281C81.4808 30.7281 80.1809 32.3385 80.1809 34.7043C80.1809 37.0918 81.4808 38.6907 83.4134 38.6907C85.3806 38.6907 86.6699 37.125 86.6699 34.7043H86.6699Z"
      fill="#FAFAFA"
    />
    <path
      d="M98.2338 36.1001C98.4212 37.7118 100.049 38.77 102.274 38.77C104.406 38.77 105.94 37.7117 105.94 36.2585C105.94 34.997 105.015 34.2417 102.825 33.724L100.634 33.2166C97.5307 32.4958 96.0898 31.1001 96.0898 28.8353C96.0898 26.0311 98.6312 24.105 102.24 24.105C105.811 24.105 108.26 26.0311 108.342 28.8353H105.789C105.636 27.2133 104.242 26.2343 102.204 26.2343C100.166 26.2343 98.7721 27.2249 98.7721 28.6666C98.7721 29.8156 99.6626 30.4917 101.841 31.0093L103.703 31.4489C107.171 32.2375 108.612 33.577 108.612 35.9543C108.612 38.9949 106.093 40.8993 102.087 40.8993C98.3387 40.8993 95.808 39.0396 95.6445 36.1L98.2338 36.1001Z"
      fill="#FAFAFA"
    />
    <path
      d="M114.07 25.9751V28.7793H116.414V30.7054H114.07V37.2378C114.07 38.2526 114.54 38.7255 115.57 38.7255C115.848 38.7208 116.126 38.702 116.402 38.6692V40.5838C115.939 40.667 115.468 40.7047 114.997 40.6963C112.502 40.6963 111.529 39.7952 111.529 37.4972V30.7054H109.737V28.7793H111.529V25.9751H114.07Z"
      fill="#FAFAFA"
    />
    <path
      d="M117.771 34.7048C117.771 30.9766 120.054 28.6338 123.615 28.6338C127.188 28.6338 129.461 30.9765 129.461 34.7048C129.461 38.4433 127.2 40.7758 123.615 40.7758C120.031 40.7758 117.771 38.4432 117.771 34.7048ZM126.883 34.7048C126.883 32.1473 125.664 30.6379 123.615 30.6379C121.565 30.6379 120.348 32.1588 120.348 34.7048C120.348 37.2725 121.565 38.7704 123.615 38.7704C125.664 38.7704 126.883 37.2725 126.883 34.7048H126.883Z"
      fill="#FAFAFA"
    />
    <path
      d="M131.547 28.7789H133.959V30.7957H134.018C134.181 30.1658 134.565 29.6092 135.105 29.2193C135.644 28.8294 136.307 28.6301 136.982 28.6549C137.273 28.6539 137.564 28.6844 137.848 28.7456V31.0207C137.481 30.9125 137.097 30.8629 136.712 30.8737C136.345 30.8594 135.978 30.9217 135.638 31.0563C135.298 31.191 134.992 31.3948 134.742 31.6538C134.492 31.9128 134.302 32.2208 134.188 32.5567C134.073 32.8927 134.035 33.2485 134.076 33.5999V40.6282H131.547L131.547 28.7789Z"
      fill="#FAFAFA"
    />
    <path
      d="M149.51 37.1485C149.17 39.2996 146.992 40.7758 144.204 40.7758C140.62 40.7758 138.395 38.4663 138.395 34.761C138.395 31.0443 140.632 28.6338 144.098 28.6338C147.507 28.6338 149.651 30.8858 149.651 34.4786V35.3119H140.948V35.4589C140.908 35.895 140.964 36.3343 141.114 36.7476C141.264 37.161 141.504 37.539 141.818 37.8564C142.131 38.1738 142.511 38.4235 142.933 38.5888C143.354 38.7541 143.808 38.8313 144.263 38.8152C144.861 38.869 145.46 38.7359 145.973 38.4356C146.486 38.1353 146.884 37.6839 147.109 37.1485L149.51 37.1485ZM140.96 33.612H147.121C147.143 33.2199 147.081 32.8275 146.939 32.4595C146.796 32.0914 146.576 31.7557 146.293 31.4733C146.009 31.1909 145.667 30.968 145.29 30.8185C144.912 30.669 144.506 30.5962 144.098 30.6046C143.686 30.6023 143.278 30.6784 142.897 30.8285C142.515 30.9787 142.169 31.2 141.877 31.4796C141.585 31.7592 141.354 32.0916 141.197 32.4576C141.039 32.8236 140.959 33.2159 140.96 33.612V33.612Z"
      fill="#FAFAFA"
    />
    <path
      d="M52.1141 12.1431C52.6444 12.1065 53.1765 12.1836 53.6721 12.3687C54.1677 12.5538 54.6144 12.8424 54.9799 13.2136C55.3455 13.5848 55.6208 14.0293 55.7861 14.5153C55.9513 15.0012 56.0023 15.5163 55.9355 16.0235C55.9355 18.5183 54.5332 19.9524 52.1141 19.9524H49.1807V12.1431H52.1141ZM50.442 18.848H51.9732C52.3522 18.8698 52.7314 18.8101 53.0833 18.6731C53.4351 18.5362 53.7509 18.3256 54.0074 18.0565C54.264 17.7875 54.4549 17.4668 54.5663 17.1179C54.6777 16.7689 54.7068 16.4005 54.6515 16.0394C54.7028 15.6797 54.6708 15.3135 54.5576 14.9671C54.4445 14.6207 54.2531 14.3027 53.9971 14.0358C53.7412 13.7689 53.427 13.5598 53.0771 13.4233C52.7272 13.2869 52.3502 13.2266 51.9732 13.2467H50.442V18.848Z"
      fill="#FAFAFA"
    />
    <path
      d="M57.3604 17.0031C57.3218 16.6158 57.3679 16.2251 57.4956 15.8559C57.6233 15.4867 57.8299 15.1473 58.102 14.8594C58.3742 14.5715 58.7059 14.3415 59.0759 14.184C59.4459 14.0266 59.846 13.9453 60.2506 13.9453C60.6552 13.9453 61.0554 14.0266 61.4254 14.184C61.7954 14.3415 62.1271 14.5715 62.3992 14.8594C62.6713 15.1473 62.8779 15.4867 63.0056 15.8559C63.1333 16.2251 63.1794 16.6158 63.1409 17.0031C63.1801 17.3908 63.1346 17.7821 63.0072 18.1519C62.8799 18.5216 62.6734 18.8617 62.4012 19.1502C62.129 19.4386 61.7971 19.6692 61.4267 19.8269C61.0563 19.9847 60.6557 20.0662 60.2506 20.0662C59.8455 20.0662 59.4449 19.9847 59.0746 19.8269C58.7042 19.6692 58.3722 19.4386 58.1 19.1502C57.8278 18.8617 57.6214 18.5216 57.494 18.1519C57.3666 17.7821 57.3211 17.3908 57.3604 17.0031ZM61.8968 17.0031C61.8968 15.7257 61.3 14.9786 60.2526 14.9786C59.2012 14.9786 58.6098 15.7257 58.6098 17.0031C58.6098 18.2908 59.2013 19.0321 60.2526 19.0321C61.3 19.0321 61.8968 18.2857 61.8968 17.0031H61.8968Z"
      fill="#FAFAFA"
    />
    <path
      d="M70.8253 19.9519H69.5706L68.3039 15.6115H68.2082L66.9468 19.9519H65.704L64.0146 14.0586H65.2415L66.3394 18.5556H66.4297L67.6898 14.0586H68.8502L70.1102 18.5556H70.2059L71.2985 14.0586H72.508L70.8253 19.9519Z"
      fill="#FAFAFA"
    />
    <path
      d="M73.9287 14.059H75.0931V14.9952H75.1834C75.3368 14.659 75.5954 14.3771 75.9232 14.1889C76.2511 14.0007 76.6318 13.9155 77.0124 13.9453C77.3106 13.9237 77.61 13.9669 77.8885 14.0718C78.167 14.1766 78.4173 14.3403 78.6211 14.5508C78.8248 14.7613 78.9767 15.0132 79.0654 15.2878C79.1541 15.5624 79.1775 15.8527 79.1337 16.1372V19.9523H77.9242V16.4293C77.9242 15.4822 77.4962 15.0112 76.6017 15.0112C76.3992 15.0021 76.1971 15.0353 76.0092 15.1083C75.8213 15.1814 75.652 15.2927 75.513 15.4345C75.374 15.5764 75.2686 15.7455 75.2039 15.9302C75.1392 16.1149 75.1168 16.3109 75.1382 16.5047V19.9524H73.9287L73.9287 14.059Z"
      fill="#FAFAFA"
    />
    <path
      d="M81.0605 11.7583H82.2701V19.9522H81.0605V11.7583Z"
      fill="#FAFAFA"
    />
    <path
      d="M83.9511 17.0032C83.9126 16.6159 83.9587 16.2251 84.0865 15.8559C84.2142 15.4868 84.4208 15.1473 84.693 14.8594C84.9652 14.5715 85.2969 14.3415 85.6669 14.184C86.037 14.0266 86.4371 13.9453 86.8417 13.9453C87.2464 13.9453 87.6465 14.0266 88.0166 14.184C88.3866 14.3415 88.7183 14.5715 88.9905 14.8594C89.2627 15.1473 89.4692 15.4868 89.597 15.8559C89.7247 16.2251 89.7708 16.6159 89.7323 17.0032C89.7716 17.3909 89.726 17.7822 89.5986 18.152C89.4711 18.5218 89.2647 18.8618 88.9924 19.1503C88.7202 19.4387 88.3882 19.6692 88.0178 19.827C87.6474 19.9847 87.2468 20.0662 86.8417 20.0662C86.4366 20.0662 86.036 19.9847 85.6656 19.827C85.2952 19.6692 84.9633 19.4387 84.691 19.1503C84.4188 18.8618 84.2123 18.5218 84.0849 18.152C83.9575 17.7822 83.9119 17.3909 83.9511 17.0032ZM88.4876 17.0032C88.4876 15.7258 87.8908 14.9787 86.8434 14.9787C85.792 14.9787 85.2006 15.7258 85.2006 17.0032C85.2006 18.2909 85.7921 19.0322 86.8434 19.0322C87.8908 19.0322 88.4876 18.2858 88.4876 17.0032H88.4876Z"
      fill="#FAFAFA"
    />
    <path
      d="M91.0059 18.2858C91.0059 17.2249 91.8273 16.6134 93.2854 16.5264L94.9455 16.4344V15.9257C94.9455 15.3033 94.5175 14.9518 93.6908 14.9518C93.0156 14.9518 92.5477 15.1902 92.4134 15.6068H91.2425C91.3661 14.5946 92.3563 13.9453 93.7466 13.9453C95.2831 13.9453 96.1497 14.6808 96.1497 15.9257V19.9524H94.9854V19.1242H94.8897C94.6954 19.4213 94.4227 19.6635 94.0993 19.826C93.7758 19.9886 93.4133 20.0657 93.0488 20.0495C92.7915 20.0752 92.5315 20.0488 92.2854 19.972C92.0394 19.8952 91.8128 19.7697 91.6204 19.6035C91.4279 19.4373 91.2738 19.2342 91.1679 19.0072C91.0621 18.7803 91.0069 18.5345 91.0059 18.2858ZM94.9455 17.7822V17.2895L93.4489 17.3815C92.6048 17.4358 92.222 17.7119 92.222 18.2314C92.222 18.7618 92.7005 19.0705 93.3585 19.0705C93.5513 19.0893 93.7461 19.0705 93.9312 19.0155C94.1163 18.9604 94.288 18.87 94.436 18.7498C94.5841 18.6296 94.7055 18.482 94.793 18.3158C94.8806 18.1495 94.9324 17.9681 94.9455 17.7822Z"
      fill="#FAFAFA"
    />
    <path
      d="M97.7393 17.003C97.7393 15.1408 98.7348 13.9611 100.283 13.9611C100.666 13.9442 101.046 14.0324 101.379 14.2155C101.712 14.3986 101.984 14.669 102.163 14.9951H102.253V11.7583H103.463V19.9522H102.304V19.0211H102.208C102.015 19.345 101.734 19.6121 101.395 19.7933C101.055 19.9746 100.671 20.0631 100.283 20.0494C98.7242 20.0494 97.7393 18.8697 97.7393 17.003ZM98.9887 17.003C98.9887 18.253 99.6015 19.0052 100.626 19.0052C101.646 19.0052 102.276 18.2422 102.276 17.0081C102.276 15.7799 101.639 15.006 100.626 15.006C99.6081 15.006 98.9887 15.7633 98.9887 17.003H98.9887Z"
      fill="#FAFAFA"
    />
    <path
      d="M108.468 17.0031C108.429 16.6158 108.475 16.2251 108.603 15.8559C108.731 15.4867 108.937 15.1473 109.209 14.8594C109.482 14.5715 109.813 14.3415 110.183 14.184C110.553 14.0266 110.953 13.9453 111.358 13.9453C111.763 13.9453 112.163 14.0266 112.533 14.184C112.903 14.3415 113.234 14.5715 113.507 14.8594C113.779 15.1473 113.985 15.4867 114.113 15.8559C114.241 16.2251 114.287 16.6158 114.248 17.0031C114.288 17.3908 114.242 17.7821 114.115 18.1519C113.987 18.5216 113.781 18.8617 113.509 19.1502C113.236 19.4386 112.904 19.6692 112.534 19.8269C112.164 19.9847 111.763 20.0662 111.358 20.0662C110.953 20.0662 110.552 19.9847 110.182 19.8269C109.812 19.6692 109.48 19.4386 109.207 19.1502C108.935 18.8617 108.729 18.5216 108.601 18.1519C108.474 17.7821 108.429 17.3908 108.468 17.0031ZM113.004 17.0031C113.004 15.7257 112.407 14.9786 111.36 14.9786C110.309 14.9786 109.717 15.7257 109.717 17.0031C109.717 18.2908 110.309 19.0321 111.36 19.0321C112.407 19.0321 113.004 18.2857 113.004 17.0031Z"
      fill="#FAFAFA"
    />
    <path
      d="M115.871 14.059H117.035V14.9952H117.126C117.279 14.659 117.538 14.3771 117.866 14.1889C118.193 14.0007 118.574 13.9155 118.955 13.9453C119.253 13.9237 119.552 13.9669 119.831 14.0718C120.109 14.1766 120.36 14.3403 120.563 14.5508C120.767 14.7613 120.919 15.0132 121.008 15.2878C121.097 15.5624 121.12 15.8527 121.076 16.1372V19.9523H119.867V16.4293C119.867 15.4822 119.439 15.0112 118.544 15.0112C118.342 15.0021 118.139 15.0353 117.952 15.1083C117.764 15.1814 117.594 15.2927 117.455 15.4345C117.316 15.5764 117.211 15.7455 117.146 15.9302C117.082 16.1149 117.059 16.3109 117.081 16.5047V19.9524H115.871V14.059Z"
      fill="#FAFAFA"
    />
    <path
      d="M127.91 12.5913V14.0854H129.238V15.0651H127.91V18.0955C127.91 18.7128 128.175 18.9831 128.777 18.9831C128.931 18.9826 129.085 18.9737 129.238 18.9563V19.9251C129.021 19.9624 128.801 19.9823 128.58 19.9845C127.235 19.9845 126.7 19.5295 126.7 18.3933V15.065H125.727V14.0854H126.7V12.5913H127.91Z"
      fill="#FAFAFA"
    />
    <path
      d="M130.891 11.7583H132.09V15.006H132.185C132.346 14.6666 132.612 14.3831 132.946 14.1942C133.281 14.0053 133.667 13.9201 134.054 13.9503C134.351 13.9347 134.647 13.9824 134.923 14.0897C135.198 14.1971 135.445 14.3616 135.647 14.5714C135.848 14.7813 135.999 15.0313 136.089 15.3037C136.178 15.5761 136.204 15.8642 136.165 16.1473V19.9523H134.954V16.4342C134.954 15.4929 134.498 15.0162 133.643 15.0162C133.435 14.9998 133.226 15.0273 133.031 15.0967C132.835 15.1661 132.657 15.2759 132.51 15.4182C132.363 15.5605 132.251 15.732 132.18 15.9206C132.109 16.1092 132.082 16.3105 132.1 16.5103V19.9522H130.891L130.891 11.7583Z"
      fill="#FAFAFA"
    />
    <path
      d="M143.217 18.3613C143.053 18.8999 142.697 19.3658 142.211 19.6775C141.726 19.9892 141.142 20.1268 140.561 20.0663C140.158 20.0765 139.756 20.0021 139.385 19.8483C139.015 19.6945 138.683 19.4649 138.414 19.1755C138.144 18.8861 137.944 18.5437 137.826 18.1723C137.708 17.8008 137.675 17.4091 137.73 17.0244C137.676 16.6384 137.71 16.2459 137.827 15.8733C137.945 15.5007 138.145 15.1568 138.413 14.8649C138.68 14.5729 139.011 14.3397 139.38 14.1811C139.75 14.0225 140.151 13.9421 140.556 13.9454C142.261 13.9454 143.29 15.0657 143.29 16.9163V17.3222H138.962V17.3874C138.943 17.6036 138.972 17.8213 139.045 18.0265C139.119 18.2316 139.236 18.4197 139.39 18.5787C139.544 18.7376 139.73 18.8639 139.938 18.9495C140.145 19.0351 140.369 19.0781 140.595 19.0757C140.884 19.1091 141.177 19.059 141.437 18.9318C141.696 18.8045 141.911 18.6059 142.053 18.3612L143.217 18.3613ZM138.962 16.462H142.058C142.073 16.2642 142.045 16.0655 141.976 15.8787C141.907 15.692 141.797 15.5212 141.655 15.3774C141.513 15.2336 141.341 15.12 141.151 15.0439C140.96 14.9677 140.756 14.9308 140.549 14.9353C140.34 14.9328 140.133 14.9705 139.939 15.0464C139.745 15.1222 139.569 15.2346 139.421 15.3769C139.273 15.5192 139.156 15.6885 139.078 15.8748C138.999 16.0612 138.96 16.2608 138.962 16.462H138.962Z"
      fill="#FAFAFA"
    />
  </svg>
</template>

<style scoped lang="scss"></style>
